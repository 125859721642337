import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Slider,
  Typography,
} from '@mui/material';
import { MuiColorInput } from 'mui-color-input';
import FeatherIcon from 'feather-icons-react';
import { QRCodeSVG } from '@akamfoad/qrcode';

import CustomTextField from '../../components/forms/custom-elements/CustomTextField';
import CustomFormLabel from '../../components/forms/custom-elements/CustomFormLabel';
import { useDebounce } from '../../hooks/debounce';
import { adminListCompanies, adminUpdateQrCode } from '../../firebase/admin';
import CustomSelect from '../../components/forms/custom-elements/CustomSelect';
import { uploadFile } from '../../components/forms/files';

const QRCodeEditPage = ({ qrCode, associateId, onClose }) => {
  const [editState, setEditState] = React.useState({});
  const [companies, setCompanies] = React.useState([]);
  const [progressPercent, setProgressPercent] = React.useState(0);

  const [saving, setSaving] = React.useState(false);

  React.useEffect(() => {
    // TODO this will work until 1000 clients
    adminListCompanies(0, 1000).then(({ list, page, size, totalSize }) => {
      setCompanies(list);
    }).catch((error) => {
      console.error(error);
    });
  }, []);

  const preview = React.useMemo(() => {
    return btoa(JSON.stringify({
      logo: editState.logo || qrCode.logo,
      logoWidth: editState.logoWidth || qrCode.logoWidth,
      backgroundColor: editState.backgroundColor || qrCode.backgroundColor,
      color: editState.color || qrCode.color,
      headerBackgroundColor: editState.headerBackgroundColor || qrCode.headerBackgroundColor,
      headerColor: editState.headerColor || qrCode.headerColor,
      subTitle: editState.subTitle || qrCode.subTitle,
      placeholder: editState.placeholder || qrCode.placeholder,
    }));
  }, [editState, qrCode]);
  const debouncedPreview = useDebounce(preview, 2000);

  const qrConfig = React.useMemo(() => {
    return { ...qrCode.qrConfig, ...editState.qrConfig }
  }, [qrCode.qrConfig, editState.qrConfig]);

  const qrCodeSVG = React.useMemo(() => {
    const qr = new QRCodeSVG(`https://qr.hollrs.com/${editState.code || qrCode.code || qrCode.id}`, qrConfig );
    return qr.toDataUrl();
  }, [qrCode.id, qrConfig]);

  const qrCodePNG = React.useMemo(() => {
    const qr = new QRCodeSVG(`https://qr.hollrs.com/${editState.code || qrCode.code || qrCode.id}`, qrConfig);
    return qr.toDataUrl();
  }, [qrCode.id, qrConfig]);

  const qrImage = React.useMemo(() => {
    return {
      source: editState.logo || qrCode.logo || 'https://qr.hollrs.com/favicon.png',
      width: '10%',
      height: '10%',
      x: 'center',
      y: 'center',
    };
  }, [editState.logo, qrCode.logo]);

  // console.log({progressPercent})

  return (
    <div>
      <Card
        sx={{
          p: 0,
        }}
      >
        <Box
          sx={{
            padding: '15px 30px',
          }}
          display='flex'
          alignItems='center'
        >
          <Box flexGrow={1}>
            <Typography fontWeight='500' variant='h4'>
              QR Code {editState.code || qrCode.code}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box
          display='flex'
          alignItems='center'
          p={2}
          sx={{
            backgroundColor: 'primary.light',
            color: 'primary.main',
          }}
        >
          <FeatherIcon icon='alert-circle' width='18' />
          <Box sx={{ ml: 1 }}>QR Info</Box>
        </Box>
        <CardContent
          sx={{
            padding: '0 30px',
          }}
        >
          <form>
            <Grid container spacing={1}>
              <Grid item lg={6} md={6} sm={6}>
                <CustomFormLabel htmlFor='fname-text'>ID <small>(Can also be used in the qr
                  code)</small></CustomFormLabel>
                <CustomTextField
                  id='fname-text'
                  variant='outlined'
                  fullWidth
                  size='small'
                  value={qrCode.id}
                  disabled
                />
                <CustomFormLabel htmlFor='fname-text'>Short Code</CustomFormLabel>
                <CustomTextField
                  id='fname-text'
                  variant='outlined'
                  fullWidth
                  size='small'
                  value={'' || editState.code || qrCode.code}
                  onChange={(e) => setEditState((state) => {
                    return { ...state, code: e.target.value };
                  })}
                />
                {!associateId && <>
                  <CustomFormLabel htmlFor='fname-text'>Company</CustomFormLabel>
                  <CustomSelect
                    id='standard-select-currency'
                    value={'' || editState.associateId || qrCode.associateId}
                    onChange={(e) => setEditState((state) => {
                      return { ...state, associateId: e.target.value };
                    })}
                    fullWidth
                    variant='outlined'
                    size='small'
                  >
                    <MenuItem key='none' value='none'>
                      - None -
                    </MenuItem>
                    {companies.map((company) => (
                      <MenuItem key={company.id} value={company.id}>
                        {company.user.firstName}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                </>}
                <CustomFormLabel htmlFor='fname-text'>Subtitle</CustomFormLabel>
                <CustomTextField
                  id='fname-text'
                  variant='outlined'
                  fullWidth
                  size='small'
                  value={'' || editState.subTitle || qrCode.subTitle}
                  onChange={(e) => setEditState((state) => {
                    return { ...state, subTitle: e.target.value };
                  })}
                />
                <CustomFormLabel htmlFor='fname-text'>Placeholder</CustomFormLabel>
                <CustomTextField
                  id='fname-text'
                  variant='outlined'
                  fullWidth
                  size='small'
                  value={'' || editState.placeholder || qrCode.placeholder}
                  onChange={(e) => setEditState((state) => {
                    return { ...state, placeholder: e.target.value };
                  })}
                />
                <CustomFormLabel htmlFor='fname-text'>Logo</CustomFormLabel>
                <CustomTextField
                  id='fname-text'
                  variant='outlined'
                  fullWidth
                  size='small'
                  value={'' || editState.logo || qrCode.logo}
                  onChange={(e) => setEditState((state) => {
                    return { ...state, logo: e.target.value };
                  })}
                />
                <input
                  type='file'
                  onChange={(e) => {
                    uploadFile(e, setProgressPercent, (downloadURL) => {
                      setEditState(state => ({ ...state, logo: downloadURL }));
                    });
                  }}
                />
                <CustomFormLabel htmlFor='fname-text'>Logo width (in % or px)</CustomFormLabel>
                <CustomTextField
                  id='fname-text'
                  variant='outlined'
                  fullWidth
                  size='small'
                  value={'' || editState.logoWidth || qrCode.logoWidth || '50%'}
                  onChange={(e) => setEditState((state) => {
                    return { ...state, logoWidth: e.target.value };
                  })}
                />
                <Grid container spacing={1}>
                  <Grid item lg={6} md={6} sm={6}>
                    <CustomFormLabel htmlFor='fname-text'>Text color</CustomFormLabel>
                    <MuiColorInput
                      format='hex'
                      value={'' || editState.color || qrCode.color || '#000'}
                      onChange={(newValue) => {
                        setEditState(state => ({ ...state, color: newValue }));
                      }}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <CustomFormLabel htmlFor='fname-text'>Background color</CustomFormLabel>
                    <MuiColorInput
                      format='hex'
                      value={'' || editState.backgroundColor || qrCode.backgroundColor || '#fff'}
                      onChange={(newValue) => {
                        setEditState(state => ({ ...state, backgroundColor: newValue }));
                      }}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <CustomFormLabel htmlFor='fname-text'>Header text color</CustomFormLabel>
                    <MuiColorInput
                      format='hex'
                      value={'' || editState.headerColor || qrCode.headerColor || '#000'}
                      onChange={(newValue) => {
                        setEditState(state => ({ ...state, headerColor: newValue }));
                      }}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <CustomFormLabel htmlFor='fname-text'>Header background color</CustomFormLabel>
                    <MuiColorInput
                      format='hex'
                      value={'' || editState.headerBackgroundColor || qrCode.headerBackgroundColor || '#fff'}
                      onChange={(newValue) => {
                        setEditState(state => ({ ...state, headerBackgroundColor: newValue }));
                      }}
                    />
                  </Grid>
                </Grid>
                <CustomFormLabel htmlFor='fname-text'>Tags <small>(comma separated)</small></CustomFormLabel>
                <CustomTextField
                  id='fname-text'
                  variant='outlined'
                  fullWidth
                  size='small'
                  value={'' || editState.tags ? editState.tags.join(',') : qrCode.tags?.join(',')}
                  onChange={(e) => setEditState((state) => {
                    return { ...state, tags: e.target.value.length > 0 ? e.target.value.split(',') : [] };
                  })}
                />
                <CustomFormLabel htmlFor='fname-text'>Hidden Tags <small>(comma separated)</small></CustomFormLabel>
                <CustomTextField
                  id='fname-text'
                  variant='outlined'
                  fullWidth
                  size='small'
                  value={'' || editState.hiddenTags ? editState.hiddenTags.join(',') : qrCode.hiddenTags?.join(',')}
                  onChange={(e) => setEditState((state) => {
                    return { ...state, hiddenTags: e.target.value.length > 0 ? e.target.value.split(',') : [] };
                  })}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6}>
                <Box display='flex' justifyContent='flex-end' mt={2}>
                  <iframe
                    title='Landing page preview'
                    src={`https://hollrs-landing-prod.web.app/${debouncedPreview}`}
                    style={{
                      width: '375px',
                      height: '900px',
                      borderRadius: '10px',
                      overflow: 'hidden',
                      border: '1px solid #ddd',
                      pointerEvents: 'none',
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </form>
        </CardContent>
        <Box
          display='flex'
          alignItems='center'
          p={2}
          sx={{
            backgroundColor: 'primary.light',
            color: 'primary.main',
            marginTop: '24px',
          }}
        >
          <FeatherIcon icon='alert-circle' width='18' />
          <Box sx={{ ml: 1 }}>QR Codes - <small>Make sure to save before testing the QR codes</small></Box>
        </Box>
        <CardContent
          sx={{
            padding: '0 30px',
          }}
        >
          <CustomFormLabel htmlFor='qr-svg'>Link</CustomFormLabel>
          <b>https://qr.hollrs.com/{editState.code || qrCode.code || qrCode.id}</b>
          <Grid container spacing={1}>
            <Grid item lg={6} md={6} sm={12}>
              <CustomFormLabel htmlFor='qr-svg'>SVG</CustomFormLabel>
              <img src={qrCodeSVG} alt='SVG qr Code' />
            </Grid>
            <Grid item lg={6} md={6} sm={12}>
              <CustomFormLabel htmlFor='qr-svg'>PNG</CustomFormLabel>
              <img src={qrCodePNG} alt='PNG qr Code' />
            </Grid>
          </Grid>
        </CardContent>
        <Box
          display='flex'
          alignItems='center'
          p={2}
          sx={{
            backgroundColor: 'primary.light',
            color: 'primary.main',
            marginTop: '24px',
          }}
        >
          <FeatherIcon icon='alert-circle' width='18' />
          <Box sx={{ ml: 1 }}>QR Code Settings</Box>
        </Box>
        <CardContent
          sx={{
            padding: '0 30px',
          }}
        >
          <Grid container spacing={3}>
            {/*
            <Grid item lg={6} md={6} sm={12}>
              <CustomFormLabel htmlFor='lname-text'>Show image</CustomFormLabel>
              <CustomCheckbox
                checked={typeof editState.qrConfig?.showImage !== 'undefined' ? editState.qrConfig?.showImage : qrCode.qrConfig?.showImage}
                onChange={(e) => setEditState((state) => {
                  if (e.target.checked) {
                    return { ...state, qrConfig: { ...state.qrConfig, image: qrImage, showImage: e.target.checked } };
                  }

                  return { ...state, qrConfig: { ...state.qrConfig, image: null, showImage: e.target.checked } };
                })}
              /> Show image in center of QR Code
            </Grid>
            */}
            <Grid item lg={6} md={6} sm={6}>
              <CustomFormLabel htmlFor='fname-text'>Background color</CustomFormLabel>
              <MuiColorInput
                format='hex'
                value={'' || editState.qrConfig?.bgColor || qrCode.qrConfig?.bgColor || '#fff'}
                onChange={(newValue) => {
                  setEditState(state => ({ ...state, qrConfig: { ...state.qrConfig, bgColor: newValue } } ));
                }}
              />
              <CustomFormLabel htmlFor='fname-text'>Foreground color</CustomFormLabel>
              <MuiColorInput
                format='hex'
                value={'' || editState.qrConfig?.fgColor || qrCode.qrConfig?.fgColor || '#000'}
                onChange={(newValue) => {
                  setEditState(state => ({ ...state, qrConfig: { ...state.qrConfig, fgColor: newValue } } ));
                }}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12}>
              <CustomFormLabel>Image Padding</CustomFormLabel>
              <Slider
                aria-label='Temperature'
                defaultValue={'' || editState.qrConfig?.padding || qrCode.qrConfig?.padding || 2}
                getAriaValueText={() => 'text'}
                valueLabelDisplay='auto'
                step={2}
                marks
                min={0}
                max={20}
                onChange={(e, value) => {
                  setEditState((state) => {
                    return { ...state, qrConfig: { ...state.qrConfig, padding: value } };
                  });
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Box pt={3}>
          <Divider />
          <Box p={3} display='flex' justifyContent='flex-end'>
            <Button
              variant='contained'
              color='error'
              sx={{
                mr: 1,
              }}
              onClick={(e) => {
                e.preventDefault();
                onClose({ id: qrCode.id, action: 'cancel' });
                setEditState({});
              }}
              disabled={saving}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              color='primary'
              onClick={async (e) => {
                e.preventDefault();
                setSaving(true);
                try {
                  // update associate info in db
                  await adminUpdateQrCode(qrCode.id, editState);
                  onClose({ id: qrCode.id, editState, action: 'submit' });
                  setEditState({});
                } catch (e) {
                  alert(e);
                  console.log(e);
                }
                setSaving(false);
              }}
              disabled={saving}
            >
              {saving && <CircularProgress size={24} />}
              Submit
            </Button>
          </Box>
        </Box>
      </Card>
    </div>
  );
};

export default QRCodeEditPage;
