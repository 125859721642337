import { createContext, useEffect, useState } from 'react';
import { GoogleAuthProvider, onAuthStateChanged, signOut } from 'firebase/auth';
import { auth, firestore, functions } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';

// Create a context
export const AuthContext = createContext({});
export const googleProvider = new GoogleAuthProvider();
googleProvider.addScope('https://www.googleapis.com/auth/contacts.readonly');

// eslint-disable-next-line react/prop-types
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loggingIn, setLoggingIn] = useState(true);

  useEffect(() => {
    onAuthStateChanged(auth, async () => {
      console.log('Auth state changed', auth);
      const user = auth.currentUser;

      // get extra user info from user object
      if (user?.uid) {
        const userRef = doc(firestore, 'users', user.uid);
        const userDoc = await getDoc(userRef);
        user.profile = userDoc.data();

        // get associate info, or cancel login
        const result = await httpsCallable(functions, 'associateGet')({
        }).catch(e => {
          console.error(e);
        });

        if (!result || !result.data || !result.data.associate) {
          console.error('No associate data');

          signOut(auth);
          return;
        }
        const associateData = result.data.associate;

        if (!associateData.isAssociate || Object.keys(associateData.associate).length === 0) {
          console.error('No associate data');

          signOut(auth);
          return;
        }

        user.associate = associateData.associate;
        user.isAdmin = associateData.isAdmin;

        // get the select user associate Id from local storage
        const associateId = localStorage.getItem('associateId');
        if (associateId && associateData.associate[associateId]) {
          user.associateId = associateId;
        } else {
          user.associateId = Object.keys(associateData.associate)[0]
          // save in local storage
          localStorage.setItem('associateId', user.associateId);
        }
      }
      //console.log({user})

      setCurrentUser(user);
      setLoggingIn(false);
    });
  }, [auth]);

  return (
    <AuthContext.Provider value={{ currentUser, loggingIn }}>
      {children}
    </AuthContext.Provider>
  );
};

export { auth };
