import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase';

const DEBUG = true;

export const adminListHollrs = async (page = 0, size = 1000) => {
  if (DEBUG) {
    console.time('FIREBASE: adminListHollrs');
  }
  const instance = httpsCallable(functions, 'adminListHollrs');
  try {
    const res = await instance({
      page,
      size,
    });

    if (DEBUG) {
      console.timeEnd('FIREBASE: adminListHollrs');
    }
    return res?.data?.posts;
  } catch (error) {
    console.error(error);
    if (DEBUG) {
      console.timeEnd('FIREBASE: adminListHollrs');
    }
    return null;
  }
};

export const adminListReportedHollrs = async (page = 0, size = 1000) => {
  if (DEBUG) {
    console.time('FIREBASE: adminListReportedHollrs');
  }
  const instance = httpsCallable(functions, 'adminListReportedHollrs');
  try {
    const res = await instance({
      page,
      size,
    });

    if (DEBUG) {
      console.timeEnd('FIREBASE: adminListReportedHollrs');
    }
    return res?.data?.posts;
  } catch (error) {
    console.error(error);
    if (DEBUG) {
      console.timeEnd('FIREBASE: adminListReportedHollrs');
    }
    return null;
  }
};

export const adminCallFunction = async (functionName) => {
  if (DEBUG) {
    console.time('FIREBASE: ' + functionName);
  }
  const instance = httpsCallable(functions, functionName);
  try {
    const res = await instance({});

    if (DEBUG) {
      console.timeEnd('FIREBASE: ' + functionName);
    }
    return res;
  } catch (error) {
    console.error(error);
    if (DEBUG) {
      console.timeEnd('FIREBASE: ' + functionName);
    }
    return null;
  }
}

export const adminCleanReportedHollrsFromFeeds = async () => {
  return adminCallFunction('adminCleanReportedHollrsFromFeeds');
};

export const adminAnalyseMissingPosts = async () => {
  return adminCallFunction('adminAnalyseMissingPosts');
};

export const adminListCompanies = async (page = 0, size = 1000) => {
  return adminListFunction('adminListCompanies', page, size);
}

export const adminListUsers = async (page = 0, size = 1000) => {
  return adminListFunction('adminListUsers', page, size);
}

export const adminListFunction = async (functionName, page = 0, size = 25, filter = {}) => {
  if (DEBUG) {
    console.time('FIREBASE: ' + functionName);
  }
  const instance = httpsCallable(functions, functionName);
  try {
    const res = await instance({
      page,
      size,
      filter,
    });

    if (DEBUG) {
      console.timeEnd('FIREBASE: ' + functionName);
    }
    return res?.data;
  } catch (error) {
    console.error(error);
    if (DEBUG) {
      console.timeEnd('FIREBASE: ' + functionName);
    }
    return null;
  }
};

export const adminUpdateUser = async (id, data) => {
  return adminUpdateFunction('adminUpdateUser', id, data);
}

export const adminUpdateCompany = async (id, data) => {
  return adminUpdateFunction('adminUpdateCompany', id, data);
}

export const adminUpdateAssociate = async (id, data) => {
  return adminUpdateFunction('adminUpdateAssociate', id, data);
}

export const adminUpdateQrCode = async (id, data) => {
  return adminUpdateFunction('adminUpdateQrCode', id, data);
}

export const adminCreateAssociate = async (data) => {
  return adminUpdateFunction('adminCreateAssociate', '', data);
}

export const adminCreateCompany = async (data) => {
  return adminUpdateFunction('adminCreateCompany', '', data);
}

export const adminCreateQrCode = async (data) => {
  return adminUpdateFunction('adminCreateQrCode', '', data);
}

export const adminCreateCompanyHuddl = async (data) => {
  return adminUpdateFunction('adminCreateCompanyHuddl', data.id, data);
}

export const adminUpdateFunction = async (functionName, id, data) => {
  if (DEBUG) {
    console.time('FIREBASE: ' + functionName);
  }
  const instance = httpsCallable(functions, functionName);
  const res = await instance({
    id,
    data,
  });

  if (DEBUG) {
    console.timeEnd('FIREBASE: ' + functionName);
  }
  return res;
};
