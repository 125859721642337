import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { storage } from '../../firebase';

export const uploadFile = (e, setProgressPercent, callback) => {
  e.preventDefault()
  const file = e.target?.files[0]
  if (!file) return;

  const randomId = Math.random().toString(36).slice(-8) + Math.random().toString(36).slice(-8)
  const storageRef = ref(storage, `landing/${randomId}_${file.name}`);
  const uploadTask = uploadBytesResumable(storageRef, file);

  uploadTask.on("state_changed",
    (snapshot) => {
      const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      setProgressPercent(progress);
    },
    (error) => {
      alert(error);
    },
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        callback(downloadURL);
      });
    }
  );
}
