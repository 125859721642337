function localized(text) {
  return text;
}

const regexForNames = /^[a-zA-Z]{2,25}$/;
const regexForPhoneNumber = /\d{9}$/;

export const config = {
  sections: [
    {
      title: localized('PUBLIC PROFILE'),
      fields: [
        {
          displayName: localized('Username'),
          type: 'text',
          editable: true,
          regex: regexForNames,
          key: 'username',
          placeholder: localized('Your username'),
          onChange: (event) => {
            console.log('Username changed: ', event);
          },
          onBlur: (event) => {
            console.log('Username blurred: ', event);
          },
        },
        {
          displayName: localized('Pronouns'),
          type: 'text',
          editable: true,
          key: 'pronouns',
          placeholder: localized('Your pronouns'),
        },
        {
          displayName: localized('Bio'),
          type: 'text',
          editable: true,
          key: 'bio',
          placeholder: localized('Share something about yourself...'),
        },
      ],
    },
    {
      title: localized('PRIVATE DETAILS'),
      description: localized(
        'Your private information is not visible to other users.',
      ),
      fields: [
        {
          displayName: localized('First Name'),
          type: 'text',
          editable: true,
          regex: regexForNames,
          key: 'firstName',
          placeholder: localized('Your first name'),
        },
        {
          displayName: localized('Last Name'),
          type: 'text',
          editable: true,
          regex: regexForNames,
          key: 'lastName',
          placeholder: localized('Your last name'),
        },
        {
          displayName: localized('Email Address'),
          type: 'text',
          editable: true,
          key: 'email',
          placeholder: localized('Your email address'),
        },
        {
          displayName: localized('Phone number'),
          type: 'text',
          editable: true,
          regex: regexForPhoneNumber,
          key: 'phone',
          placeholder: localized('Your phone number'),
        },
      ],
    },
    {
      title: localized('EXTRA INFORMATION'),
      description: localized(
        'A complete profile is more likely to get responses from brands.',
      ),
      fields: [
        {
          displayName: localized('Date of Birth'),
          type: 'date',
          editable: true,
          key: 'dateOfBirth',
          placeholder: localized('Your date of birth'),
        },
        {
          displayName: localized('Nationality'),
          type: 'text',
          editable: true,
          key: 'nationality',
          placeholder: localized('Your nationality'),
        },
        {
          displayName: localized('Employment status'),
          type: 'select',
          editable: true,
          key: 'employmentStatus',
          displayOptions: [
            localized('Student'),
            localized('Intern'),
            localized('Apprentice'),
            localized('Employed - Full Time'),
            localized('Employed - Part Time'),
            localized('Employed - Contract'),
            localized('Self-Employed'),
            localized('Homemaker'),
            localized('Unemployed'),
          ],
        },
        {
          displayName: localized('Occupation'),
          type: 'text',
          editable: true,
          key: 'occupation',
          placeholder: localized('Your occupation'),
          condition(alteredFormDict, initialValuesDict) {
            const employmentStatus = alteredFormDict.employmentStatus || initialValuesDict.employmentStatus;
            return employmentStatus === localized('Employed - Full Time') ||
              employmentStatus === localized('Employed - Part Time') ||
              employmentStatus === localized('Employed - Contract') ||
              employmentStatus === localized('Self-Employed');
          }
        },
        {
          displayName: localized('Student'),
          type: 'select',
          editable: true,
          key: 'student',
          displayOptions: [
            localized('School'),
            localized('Higher Education'),
            localized('College/University'),
            localized('Part Time Higher Education'),
            localized('Apprentice'),
          ],
          condition(alteredFormDict, initialValuesDict) {
            const employmentStatus = alteredFormDict.employmentStatus || initialValuesDict.employmentStatus;
            return employmentStatus === localized('Student');
          }
        },
        {
          displayName: localized('Marital status'),
          type: 'select',
          editable: true,
          key: 'maritalStatus',
          displayOptions: [
            localized('Single'),
            localized('Married'),
            localized('Divorced'),
            localized('Widowed'),
          ],
        },
        {
          displayName: localized('Number of children'),
          type: 'select',
          editable: true,
          key: 'familySize',
          displayOptions: ['1', '2', '3', '4+'],
        },
        {
          displayName: localized('Housing type'),
          type: 'select',
          editable: true,
          key: 'housingType',
          displayOptions: [
            localized('Single-Family Detached Home'),
            localized('Single-Family Attached Home'),
            localized('Multi-Family Home'),
            localized('Mobile Home'),
          ],
        },
        {
          displayName: localized('Home ownership'),
          type: 'select',
          editable: true,
          key: 'homeOwnership',
          displayOptions: [
            localized('Owned Outright'),
            localized('Mortgaged'),
            localized('Rented'),
          ],
        },
        {
          displayName: localized('Parental status'),
          type: 'select',
          editable: true,
          key: 'parentalStatus',
          displayOptions: [
            localized('Single Parent'),
            localized('Married/Cohabiting'),
            localized('Dual Parent'),
            localized('Other'),
          ],
        },
      ],
    },
    {
      title: localized('SOCIAL INFORMATION'),
      fields: [
        {
          displayName: localized('Post count'),
          type: 'text',
          editable: false,
          key: 'postCount',
        },
        {
          displayName: localized('Reaction count'),
          type: 'text',
          editable: false,
          key: 'reactionsCount',
        },
      ],
    },
    {
      title: localized('SYSTEM INFORMATION'),
      fields: [
        {
          displayName: localized('App identifier'),
          type: 'text',
          editable: false,
          key: 'appIdentifier',
        },
      ],
    },
  ],
}


