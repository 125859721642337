import React from 'react';
import {
  Avatar,
  Box,
  Button,
  Card, CircularProgress, Grid,
  Step,
  StepLabel,
  Stepper, Table, TableBody, TableCell, TableContainer, TableRow,
  Typography,
} from '@mui/material';
import PageContainer from '../../../components/container/PageContainer';
import CustomTextField from '../../../components/forms/custom-elements/CustomTextField';
import CustomFormLabel from '../../../components/forms/custom-elements/CustomFormLabel';
import { adminCreateCompany } from '../../../firebase/admin';
import { uploadFile } from '../../../components/forms/files';

const steps = ['Company', 'Finish'];

const AdminNewCompany = ({ onClose }) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [saving, setSaving] = React.useState(false);

  const [userData, setUserData] = React.useState({
    appIdentifier: 'associate.hollrs.com',
  });
  const [companyData, setCompanyData] = React.useState({});

  const handleNext = () => {
    if (activeStep === 0) {
      // do nothing...
    } else if (activeStep === steps.length - 1) {
      // submit
      setSaving(true);
      adminCreateCompany({
        userData,
        companyData,
      }).then(response => {
        // pass data back to onClose
        setSaving(false);
        onClose({ action: 'submit', response, userData, companyData });
      }).catch(error => {
        alert(error);
        console.log(error);
        setSaving(false);
      });
      return
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 0) {
      if (confirm('Are you sure you want to quit the wizard?')) {
        onClose({ action: 'cancel' })
      }
      return
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // eslint-disable-next-line consistent-return
  const handleSteps = (step) => {
    switch (step) {
      case 0:
        return (
          <Box sx={{ p: 3 }}>
            <CustomFormLabel htmlFor='Name'>Company Name</CustomFormLabel>
            <CustomTextField
              id='Name'
              size='small'
              variant='outlined'
              value={userData.firstName}
              fullWidth
              sx={{ mt: 1, mb: 3 }}
              onChange={(e) => {
                setUserData({ ...userData, firstName: e.target.value });
              }}
            />
            <CustomFormLabel htmlFor='Name'>Username</CustomFormLabel>
            <CustomTextField
              id='Name'
              size='small'
              variant='outlined'
              value={userData.username}
              fullWidth
              sx={{ mt: 1, mb: 3 }}
              onChange={(e) => {
                setUserData({ ...userData, username: e.target.value });
              }}
            />
            <CustomFormLabel htmlFor='Email'>Email</CustomFormLabel>
            <CustomTextField
              id='Email'
              size='small'
              type='email'
              variant='outlined'
              value={userData.email}
              fullWidth
              sx={{ mt: 1, mb: 3 }}
              onChange={(e) => {
                setUserData({ ...userData, email: e.target.value });
              }}
            />
            <CustomFormLabel htmlFor='fname-text'>Profile Picture</CustomFormLabel>
            <CustomTextField
              id='fname-text'
              variant='outlined'
              fullWidth
              size='small'
              value={userData.profilePictureURL}
              onChange={(e) => setUserData((state) => {
                return { ...state, profilePictureURL: e.target.value };
              })}
            />
            <input
              type='file'
              onChange={(e) => {
                uploadFile(e, () => {}, (downloadURL) => {
                  setUserData(state => ({ ...state, profilePictureURL: downloadURL }));
                });
              }}
            />
          </Box>
        );
      case 1:
        return (
          <Box sx={{ p: 3 }}>
            {/* show the details on read-only before sunmitting */}
            <Box display="flex" flexGrow={1} flexDirection="row" alignItems="center">
              <Avatar
                src={userData.profilePictureURL}
                alt={userData.profilePictureURL}
                width='30'
                sx={{
                  borderRadius: '100%',
                  marginRight: "16px",
                }}
              />

              <Typography variant='h4'>{userData.firstName}</Typography>
            </Box>
            <Typography variant='body1'>Username: {userData.username}</Typography>
            <Typography variant='body1'>Email: {userData.email}</Typography>
          </Box>
        );
      default:
        break;
    }
  };

  const handleReset = () => {
    setActiveStep(0);
    setUserData({
      appIdentifier: 'associate.hollrs.com',
    });
    setCompanyData({});
  };

  return (
    <PageContainer>
      <Card>
        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === steps.length ? (
            <>
              <Box sx={{ m: 3, p: 2, backgroundColor: 'primary.light', borderRadius: 1 }}>
                All steps completed - you&apos;re finished
              </Box>

              <Box display='flex' sx={{ flexDirection: 'row', p: 3 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleReset} variant='contained' color='error'>
                  Reset
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Box>{handleSteps(activeStep)}</Box>

              <Box display='flex' sx={{ flexDirection: 'row', p: 3 }}>
                <Button
                  color='inherit'
                  variant='contained'
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                  disabled={saving}
                >
                  Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button
                  onClick={handleNext}
                  variant='contained'
                  color={activeStep === steps.length - 1 ? 'success' : 'secondary'}
                  disabled={saving}
                >
                  {saving && <CircularProgress size="16px" />}
                  {activeStep === steps.length - 1 ? 'Create Company' : 'Next'}
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Card>
    </PageContainer>
  );
};

export default AdminNewCompany;
