import { collection } from 'firebase/firestore';
import { firestore, functions } from '../firebase';
import { httpsCallable } from 'firebase/functions';

const usersRef = collection(firestore, 'users')
const associatesRef = collection(firestore, 'associates')

export const updateUser = async (userID, newData) => {
  const dataWithOnlineStatus = {
    ...newData,
    lastOnlineTimestamp: Math.round(+new Date() / 1000),
  }
  try {
    await usersRef.doc(userID).set({ ...dataWithOnlineStatus }, { merge: true })
    return { success: true }
  } catch (error) {
    return error
  }
}

export const updateAssociate = async (userID, newData) => {
  const dataWithOnlineStatus = {
    ...newData,
    lastOnlineTimestamp: Math.round(+new Date() / 1000),
  }
  await associatesRef.doc(userID).set({ ...dataWithOnlineStatus }, { merge: true })
  return { success: true }
}

export const getUserByID = async userID => {
  try {
    const document = await usersRef.doc(userID).get()
    if (document) {
      return document.data()
    }
    return null
  } catch (error) {
    console.log(error)
    return null
  }
}

export const updateProfilePhoto = async (userID, profilePictureURL) => {
  try {
    await usersRef.doc(userID).update({ profilePictureURL: profilePictureURL })
    return { success: true }
  } catch (error) {
    console.log(error)
    return { error: error }
  }
}

export const registerAssociate = async (associateData) => {
  const instance = httpsCallable(functions, 'registerAssociate');
  return await instance({
    data: associateData,
  });
}
