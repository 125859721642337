import React from 'react';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import moment from 'moment/moment';

import PageContainer from '../../../components/container/PageContainer';
import { adminListUsers, adminUpdateUser } from '../../../firebase/admin';
import { TablePaginationActions } from '../../../components/table/pagination';
import FormComponent from '../../../components/forms/form';
import { config } from './editFormConfig';

const AdminUsersPage = () => {
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rowLength, setRowLength] = React.useState(0);

  const [selectedUser, setSelectedUser] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [saving, setSaving] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    adminListUsers(page, rowsPerPage).then((response) => {
      const { list, totalSize } = response;
      setRows(list || []);
      //console.log(response);
      setRowLength(totalSize || 0);
      setLoading(false);
    }).catch((err) => {
      console.error(err);
      setLoading(false);
    });
  }, [page, rowsPerPage]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowLength) : 0;

  const onClosEdit = React.useCallback(async ({ id, editState, action }) => {
    if (action === 'submit') {
      setSaving(true);
      try {
        // update user info in db
        const response = await adminUpdateUser(id, editState);
        // success: false, message: 'Username already taken'
        if (response?.data?.success === false) {
          alert(response?.data?.message || "An error occurred");
          //console.log(response);
          setSaving(false);
          return false;
        }
      } catch (e) {
        alert(e);
        console.log(e);
        setSaving(false);
        return false;
      }
      // process all key values from editState
      setRows((prev) => {
        const newRows = prev.map((row) => {
          if (row.id === id) {
            return {
              ...row,
              ...editState,
            };
          }
          return row;
        });
        return newRows;
      });
    }

    setSelectedUser(null);
    setSaving(false);
    return true;
  }, []);

  if (selectedUser) {
    return (
      <PageContainer>
        <FormComponent
          config={config}
          values={selectedUser}
          title={`User: ${selectedUser.firstName || ''} ${selectedUser.lastName || ''}`}
          onClose={onClosEdit}
          saving={saving}
        />
      </PageContainer>
    );
  }

  return (
    <PageContainer title='Admin UserList' description='Admin UserList page'>
      <Card>
        <CardContent>
          <Box
            sx={{
              overflow: {
                xs: 'auto',
                md: 'unset',
              },
            }}
          >
            <Table
              aria-label='custom pagination table'
              sx={{
                whiteSpace: 'nowrap',
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant='h5'>User</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h5'>Username</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h5'>Last online</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              {loading
                ?
                <CircularProgress />
                :
                <TableBody>
                  {rows.map((row) => (
                    <TableRow
                      key={row.id}
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedUser(row);
                      }}
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      <TableCell>
                        <Box display='flex' alignItems='center'>
                          <Avatar
                            src={row.profilePictureURL}
                            alt={row.profilePictureURL}
                            width='30'
                            sx={{
                              borderRadius: '100%',
                            }}
                          />
                          <Box
                            sx={{
                              ml: 2,
                            }}
                          >
                            <Typography variant='h6' fontWeight='600'>
                              {row.firstName + ' ' + (row.lastName || '')}
                            </Typography>
                            <Typography variant='caption'>
                              {row.email}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Typography variant='h6'>
                          {row.username}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='h6'>
                          {row.lastOnlineTimestamp ? moment.unix(row.lastOnlineTimestamp).format('DD.MM.YYYY HH:mm') : '-'}
                        </Typography>
                        <Typography variant='h6'>
                          {row.lastOnlineTimestamp ? moment.unix(row.lastOnlineTimestamp).fromNow() : '-'}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              }
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    colSpan={6}
                    count={rowLength}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputprops: {
                        'aria-label': 'rows per page',
                      },
                      native: true,
                    }}
                    onPageChange={(e, newPage) => setPage(newPage)}
                    onRowsPerPageChange={(event) => {
                      setRowsPerPage(parseInt(event.target.value, 10));
                      setPage(0);
                    }}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </Box>
        </CardContent>
      </Card>
    </PageContainer>
  );
}

export default AdminUsersPage;
