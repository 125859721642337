import React from 'react';
import FeatherIcon from 'feather-icons-react';
import { Box, Button, Card, CardContent, CircularProgress, Divider, Grid, MenuItem, Typography } from '@mui/material';
import CustomFormLabel from './custom-elements/CustomFormLabel';
import CustomTextField from './custom-elements/CustomTextField';
import CustomSelect from './custom-elements/CustomSelect';

const FormComponent = ({ config, values, title, onClose, saving }) => {
  const [editState, setEditState] = React.useState({});

  console.log(editState);

  const renderTextField = (formTextField, index, totalLen) => {
    return (
      <Grid item lg={6} md={6} sm={12} key={formTextField.key}>
        <CustomFormLabel htmlFor="fname-text">{formTextField.displayName}</CustomFormLabel>
        <CustomTextField
          id="fname-text"
          fullWidth
          size="small"
          placeholder={formTextField.placeholder}
          value={editState[formTextField.key] || values[formTextField.key]}
          InputProps={{
            readOnly: !formTextField.editable,
          }}
          onChange={(e) => setEditState((state) => {
            return { ...state, [formTextField.key]: e.target.value };
          })}
        />
      </Grid>
    );
  };

  const renderField = (formField, index, totalLen) => {
    if (formField.condition) {
      // check condition function
      if (!formField.condition(editState, values)) {
        return null;
      }
    }

    const type = formField.type;
    if (type === 'text') {
      return renderTextField(formField, index, totalLen);
    }
    // if (type === 'switch') {
    //   return renderSwitchField(formField, index)
    // }
    // if (type === 'button') {
    //   return renderButtonField(formField, index)
    // }
    if (type === 'select') {
      return renderSelectField(formField, index);
    }
    if (type === 'date') {
      return renderDateField(formField, index);
    }
    return null;
  };

  const renderSelectField = (selectField, index) => {
    return (
      <Grid item lg={6} md={6} sm={12}>
        <CustomFormLabel htmlFor="standard-select-currency">{selectField.displayName}</CustomFormLabel>
        <CustomSelect
          id="standard-select-currency"
          value={editState[selectField.key] || values[selectField.key]}
          onChange={(e) => setEditState((state) => {
            return { ...state, [selectField.key]: e.target.value };
          })}
          fullWidth
          variant="outlined"
          size="small"
        >
          {selectField.displayOptions.map((optionText) => (
            <MenuItem key={optionText} value={optionText}>
              {optionText}
            </MenuItem>
          ))}
        </CustomSelect>
      </Grid>
    );
  };

  const renderDateField = (formDateField, index) => {
    return (
      <Grid item lg={6} md={6} sm={12}>
        <CustomFormLabel htmlFor="date">{formDateField.displayName}</CustomFormLabel>
        <CustomTextField
          id="date"
          type="date"
          variant="outlined"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          value={editState[formDateField.key] || values[formDateField.key]}
          onChange={(e) => setEditState((state) => {
            return { ...state, [formDateField.key]: e.target.value };
          })}
        />
      </Grid>
    );
  };

  const renderSection = (section, lastSection) => {
    return (
      <>
        <Box
          key={section.title}
          display="flex"
          alignItems="center"
          p={2}
          sx={{
            backgroundColor: 'primary.light',
            color: 'primary.main',
          }}
        >
          <FeatherIcon icon="alert-circle" width="18" />
          <Box sx={{ ml: 1 }}>{section.title}</Box>
        </Box>
        <CardContent
          sx={{
            padding: '0 30px',
            marginBottom: lastSection ? '0' : '24px',
          }}
        >
          <form>
            <Grid container spacing={1}>
              {section.fields.map((field, index) =>
                renderField(field, index, section.fields.length),
              )}
            </Grid>
          </form>
        </CardContent>
        {lastSection && <Box pt={3}>
          <Divider />
          <Box p={3} display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              color="error"
              sx={{
                mr: 1,
              }}
              onClick={(e) => {
                e.preventDefault();
                onClose({ id: values.id, action: 'cancel' });
                setEditState({});
              }}
              disabled={saving}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={async (e) => {
                e.preventDefault();
                if (await onClose({ id: values.id, editState, action: 'submit' })) {
                  setEditState({});
                }
              }}
              disabled={saving}
            >
              {saving && <CircularProgress size={'16px'} style={{ marginRight: '8px' }} />}
              Save
            </Button>
          </Box>
        </Box>}
      </>
    );
  };

  return (
    <Card
      sx={{
        p: 0,
      }}
    >
      <Box
        sx={{
          padding: '15px 30px',
        }}
        display="flex"
        alignItems="center"
      >
        <Box flexGrow={1}>
          <Typography fontWeight="500" variant="h4">
            {title}
          </Typography>
        </Box>
      </Box>
      <Divider />
      {config.sections.map((section, index) => renderSection(section, index === config.sections.length - 1))}
    </Card>
  );
};

export default FormComponent;