import React from 'react';
import { Box, Button, CircularProgress, Grid, Typography, Alert } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { loginWithEmailAndPassword } from '../../firebase/authClient';
import { AuthContext } from '../../contexts/AuthProvider';
import { useLocalStorage } from '../../hooks/localstorage';
import CustomTextField from '../../components/forms/custom-elements/CustomTextField';
import CustomFormLabel from '../../components/forms/custom-elements/CustomFormLabel';
import PageContainer from '../../components/container/PageContainer';

import img1 from '../../assets/images/backgrounds/login-bg.svg';
import LogoIcon from '../../layouts/full-layout/logo/LogoIcon';
import * as PropTypes from 'prop-types';
import { localizedErrorMessage } from '../../firebase/ErrorCode';
import { updateAssociate } from '../../firebase/userClient';

const Login = () => {
  const { loggingIn } = React.useContext(AuthContext);
  const navigate = useNavigate();
  const { t } = useTranslation('auth');

  const [email, setEmail] = useLocalStorage('email', '');
  const [password, setPassword] = React.useState('');

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const signIn = React.useCallback(async (event) => {
    event.preventDefault();

    setLoading(true);
    setError(null);

    if (!email || !password) {
      setError(t('email or password is empty'));
      setLoading(false);
      return;
    }

    //let errorCode = null;
    loginWithEmailAndPassword(email, password).then((response) => {
      // update associate login stuff
      const userData = {
        lastLoginAt: Math.round(+new Date()/1000),
      };

      updateAssociate(response.user.id, userData).catch(error => {
        console.error('Error updating associate: ', error);
      });

      if (currentLocation.includes('login')) {
        navigate('/');
      }
      setLoading(false);
    }).catch((error) => {
      console.log({error})
      setError(localizedErrorMessage(error, t));
      //errorCode = error;
      setLoading(false);
    });

    const currentLocation = window.location.href;
  }, [email, password]);

  return (
    <PageContainer title="Login" description="this is Login page">
      <Grid container spacing={0} sx={{ height: '100vh', justifyContent: 'center' }}>
        <Grid
          item
          xs={12}
          sm={12}
          lg={6}
          sx={{
            background: (theme) => `${theme.palette.mode === 'dark' ? '#1c1f25' : '#ffffff'}`,
          }}
        >
          <Box sx={{ position: 'relative' }}>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='center'
              sx={{
                position: {
                  xs: 'relative',
                  lg: 'absolute',
                },
                height: { xs: 'auto', lg: '100vh' },
                right: { xs: 'auto', lg: '-50px' },
                margin: '0 auto',
              }}
            >
              <img src={img1} alt='bg' style={{ width: '100%', maxWidth: '812px' }} />
            </Box>

            <Box sx={{ p: 4, position: 'absolute', top: '0' }}>
              <LogoIcon />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={8} lg={6} display='flex' alignItems='center'>
          <Grid container spacing={0} display='flex' justifyContent='center'>
            <Grid item xs={12} lg={9} xl={6}>
              <Box sx={{ p: 4 }}>
                {loggingIn ? (
                  <Typography fontWeight='700' variant='h2'>
                    Loading...
                  </Typography>
                ) : (
                  <>
                    <Typography fontWeight='700' variant='h2'>
                      {t('Sign In')}
                    </Typography>
                    <Typography
                      color='textSecondary'
                      variant='h6'
                      fontWeight='500'
                      sx={{
                        mr: 1,
                      }}
                    >
                      {t('Enter your email and password to sign in to your Hollrs account.')}
                    </Typography>
                    {!!error && (
                      <Alert variant='filled' severity='error'>
                        {error}
                      </Alert>
                    )}

                    <Box sx={{ mt: 4 }}>
                      <CustomFormLabel htmlFor='email'>{t('Email Address')}</CustomFormLabel>
                      <CustomTextField
                        id='email'
                        name='email'
                        value={email}
                        variant='outlined'
                        fullWidth
                        onChange={(event) => setEmail(event.target.value)}
                        disabled={loggingIn || loading}
                      />
                      <CustomFormLabel htmlFor='password'>{t('Password')}</CustomFormLabel>
                      <CustomTextField
                        id='password'
                        name='password'
                        value={password}
                        type='password'
                        variant='outlined'
                        fullWidth
                        sx={{
                          mb: 3,
                        }}
                        onChange={(event) => setPassword(event.target.value)}
                        disabled={loggingIn || loading}
                      />
                      <Box
                        sx={{
                          display: {
                            xs: 'block',
                            sm: 'flex',
                            lg: 'flex',
                          },
                          alignItems: 'center',
                        }}
                      >
                        {/*
                  <FormGroup>
                    <FormControlLabel
                      control={<CustomCheckbox defaultChecked />}
                      label="Remember this Device"
                      sx={{
                        mb: 2,
                      }}
                    />
                  </FormGroup>
                  */}
                        <Box
                          sx={{
                            ml: 'auto',
                          }}
                        >
                          <Typography
                            component={Link}
                            to='/auth/reset-password'
                            fontWeight='500'
                            sx={{
                              display: 'block',
                              textDecoration: 'none',
                              mb: '16px',
                              color: 'primary.main',
                            }}
                          >
                            {t('Forgot Password ?')}
                          </Typography>
                        </Box>
                      </Box>

                      <Button
                        color='secondary'
                        variant='contained'
                        size='large'
                        fullWidth
                        component={Link}
                        sx={{
                          pt: '10px',
                          pb: '10px',
                        }}
                        onClick={signIn}
                        disabled={loggingIn || loading}
                      >
                        {(loggingIn || loading) && <CircularProgress size={16} style={{ marginRight: 10 }}/>}
                        Sign In
                      </Button>

                      {/*
                  <Separator title="or sign in with" />

                  <Box>
                    <Button
                      variant="outlined"
                      size="large"
                      display="flex"
                      alignitems="center"
                      justifycontent="center"
                      sx={{
                        width: '100%',
                        borderColor: (theme) =>
                          `${theme.palette.mode === 'dark' ? '#42464d' : '#dde3e8'}`,
                        borderWidth: '2px',
                        textAlign: 'center',
                        mt: 2,
                        pt: '10px',
                        pb: '10px',
                        '&:hover': {
                          borderColor: (theme) =>
                            `${theme.palette.mode === 'dark' ? '#42464d' : '#dde3e8'}`,
                          borderWidth: '2px',
                        },
                      }}
                    >
                      <Box display="flex" alignItems="center">
                        <GoogleIcon
                          sx={{
                            color: (theme) => theme.palette.error.main,
                          }}
                        />
                        <Typography
                          variant="h6"
                          sx={{
                            ml: 1,
                            color: (theme) =>
                              `${
                                theme.palette.mode === 'dark' ? theme.palette.grey.A200 : '#13152a'
                              }`,
                          }}
                        >
                          Google
                        </Typography>
                      </Box>
                    </Button>
                  </Box>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} lg={6}>
                      <Button
                        variant="outlined"
                        size="large"
                        display="flex"
                        alignitems="center"
                        justifycontent="center"
                        sx={{
                          width: '100%',
                          borderColor: (theme) =>
                            `${theme.palette.mode === 'dark' ? '#42464d' : '#dde3e8'}`,
                          borderWidth: '2px',
                          textAlign: 'center',
                          mt: 2,
                          pt: '10px',
                          pb: '10px',
                          '&:hover': {
                            borderColor: (theme) =>
                              `${theme.palette.mode === 'dark' ? '#42464d' : '#dde3e8'}`,
                            borderWidth: '2px',
                          },
                        }}
                      >
                        <Box display="flex" alignItems="center">
                          <FacebookIcon
                            sx={{
                              color: (theme) => theme.palette.secondary.main,
                            }}
                          />
                          <Typography
                            variant="h6"
                            sx={{
                              ml: 1,
                              color: (theme) =>
                                `${
                                  theme.palette.mode === 'dark' ? theme.palette.grey.A200 : '#13152a'
                                }`,
                            }}
                          >
                            Facebook
                          </Typography>
                        </Box>
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6}>
                      <Button
                        variant="outlined"
                        size="large"
                        display="flex"
                        alignitems="center"
                        justifycontent="center"
                        sx={{
                          width: '100%',
                          borderColor: (theme) =>
                            `${theme.palette.mode === 'dark' ? '#42464d' : '#dde3e8'}`,
                          borderWidth: '2px',
                          textAlign: 'center',
                          mt: 2,
                          pt: '10px',
                          pb: '10px',
                          '&:hover': {
                            borderColor: (theme) =>
                              `${theme.palette.mode === 'dark' ? '#42464d' : '#dde3e8'}`,
                            borderWidth: '2px',
                          },
                        }}
                      >
                        <Box display="flex" alignItems="center">
                          <TwitterIcon
                            sx={{
                              color: (theme) => theme.palette.primary.main,
                            }}
                          />
                          <Typography
                            variant="h6"
                            sx={{
                              ml: 1,
                              color: (theme) =>
                                `${
                                  theme.palette.mode === 'dark' ? theme.palette.grey.A200 : '#13152a'
                                }`,
                            }}
                          >
                            Twitter
                          </Typography>
                        </Box>
                      </Button>
                    </Grid>
                  </Grid>
                  */}
                    <Separator title={t('no account yet?')} />
                    <Box display='flex' flexDirection='column' alignItems='center'>
                      <Typography
                        component={Link}
                        to='/auth/register'
                        fontWeight='500'
                        sx={{
                          display: 'block',
                          textDecoration: 'none',
                          color: 'primary.main',
                        }}
                      >
                        {t('Create an account')}
                      </Typography>
                    </Box>
                    </Box>
                  </>)}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PageContainer>
  )
    ;
};

export default Login;

export const Separator = function(
  {
    title,
    lineColor,
    titleBackgroundColor,
  }
) {
  return <Box
    sx={{
      position: 'relative',
      textAlign: 'center',
      mt: '20px',
      mb: '20px',
      '&::before': {
        content: '""',
        background: lineColor || ((theme) => `${theme.palette.mode === 'dark' ? '#42464d' : '#ecf0f2'}`),
        height: '1px',
        width: '100%',
        position: 'absolute',
        left: '0',
        top: '13px',
      },
    }}
  >
    <Typography
      component='span'
      color='textSecondary'
      variant='h6'
      fontWeight='400'
      sx={{
        position: 'relative',
        padding: '0 12px',
        background: titleBackgroundColor || ((theme) => `${theme.palette.mode === 'dark' ? '#282c34' : '#fff'}`),
      }}
    >
      {title || ''}
    </Typography>
  </Box>;
}

Separator.propTypes = {
  title: PropTypes.string,
  lineColor: PropTypes.func,
  titleBackgroundColor: PropTypes.func,
};
